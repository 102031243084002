import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import mapTexas from '../../assets/images/mapTexas.png'
import SEO from '../../components/seo'

class ServiceAreaIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All Service Areas"
          description="We Install and Repair Gates and Gate Access Systems. Call  Today! We Service Cities in the Dallas-Fort Worth, TX Area"
          // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div id="blog-hero-section" className="blog-hero-section text-center">
          <h1>Dallas-Fort Worth Gates & Access Systems</h1>
        </div>
        <div className="container custom-container my-5">
          <div className="row px-3">
            <p>
              We install and repair gates and gate access systems, and service
              all cities in the Dallas-Fort Worth metroplex. Give us a call
              today for a free estimate at.
            </p>
            <div className="maps-texas">
              <div className="main-image text-center">
                <img src={mapTexas} className="float-right" alt="map" />
              </div>
              <h3>A Local Name You Can Trust</h3>
              <p>
                <i>
                  Hi, my name is Aaron, and I’m the owner of J & J Gates Service
                  and Design. I would like to say it’s an honor to service the
                  DFW community. I make sure to perform each project with
                  honesty and excellence, and work hard to earn your trust and
                  satisfaction.
                </i>
              </p>
            </div>
            <h3>Gate Repair Near You!</h3>
            <p>
              We are centrally located in Arlington, TX to best service the
              entire Dallas-Fort Worth metroplex. From west of Fort Worth to
              East of Dallas and everything in between, J & J Gates Service and
              Design is happy to provide you with the best gate services in the
              region. Here are some cities, just to name a few, that we work in
              frequently:
            </p>
            <div className="country-list">
              <h5 className="font-normal">
                <Link to="/service-area/texas">Texas :</Link>
              </h5>

              <ul className="list-unstyled">
                <li>
                  <Link to="/service-area/texas/collin-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Collin
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/dallas-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Dallas
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/denton-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Denton
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/ellis-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Ellis
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/hood-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Hood County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/johnson-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Johnson
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/parker-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Parker
                    County
                  </Link>
                </li>
                <li>
                  <Link to="/service-area/texas/tarrant-county">
                    <i className="fa fa-chevron-right fa-sm mr-2" /> Tarrant
                    County
                  </Link>
                </li>
              </ul>
            </div>
            <div className="list-county">
              <h3 className="mt-2">We Know DFW</h3>
              <p>
                Our company works hard to stay proficient in the gate industry.
                Feel free to browse our site for a more comprehensive{' '}
                <Link to="/gates/services/">list of our gate services</Link> ,
                but to summarize, we can fix your gate if it’s broken, and if
                you want a new gate installed, we can do that too! We install
                and repair residential and commercial gates, and carry all the{' '}
                <Link to="/gates/gate-brands/">best known brands</Link> to
                ensure long lasting durability.
              </p>
              <p>
                If you need gate services in Dallas or Fort Worth, TX, don’t
                hesitate to give us a call! We are happy to answer any questions
                you have about our products and services. <br />
                <strong>
                  <i> Call us today at !</i>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ServiceAreaIndex

export const ServiceAreaIndexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
